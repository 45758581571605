<template>
  <div class="recruitmentPositions">
    <div class="detailsWrap">
      <div class="AppointmentWrap" style="flex-direction: column;align-items: flex-start;flex-wrap: nowrap">
        <div class="Item" v-for="(item, index) in appointmentList" :key="index" style="border: 1px solid #E6E6E6">
          <Appointment type="recommended" @call="eventCall" :appointmentInfo="item"/>
        </div>
        <div class="pagination">
          <el-pagination
              background
              layout="prev, pager, next, total"
              :total="totalCount"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
      <div class="details">
        <div class="header">
          <div class="info">
            <div class="name">{{ details?.job }}</div>
            <div class="infoList">
              <div class="item">
                <img src="https://static.dasdt.cn/images/adress3.png">
                <span>{{ details?.cityName }}·{{ details?.areaName }}</span>
              </div>
              <div class="item">
                <img src="https://static.dasdt.cn/images/education.png">
                <span>{{ details?.education }}</span>
              </div>
              <div class="item">
                <img src="https://static.dasdt.cn/images/experience.png">
                <span>{{ details?.jobExp }}</span>
              </div>
            </div>
          </div>
          <div class="btnList">
            <div class="star" v-show="isShow" @click="eventCollection">
              <img src="https://static.dasdt.cn/images/star2.png">
              <span>收藏</span>
            </div>
            <div class="star"  style="background: #fff;border: 1px solid #ADADAD" @click="eventCollection"
                 v-show="!isShow">
              <img src="https://static.dasdt.cn/images/star5.png">
              <span style="color: #ADADAD">已收藏</span>
            </div>
            <div class="item" v-if="!details?.isDeliver" @click="deliver">
              投递简历
            </div>
            <div class="item" style="background:rgb(154, 163, 179);color: #fff" v-else>
              已投递
            </div>
          </div>
        </div>
        <div class="titles">岗位描述</div>
        <div class="tagList">
          <div class="item" v-for="item in details?.firmTagList" :key="item">
            {{ item }}
          </div>
        </div>
        <div class="responsibilityWrap">
          <div class="responsibility">岗位职责：</div>
          <div class="responsibility" style="white-space: pre-wrap;">{{ details?.duty }}</div>
        </div>
        <div class="responsibilityWrap">
          <div class="responsibility">任职要求：</div>
          <div class="responsibility" style="white-space: pre-wrap;">{{ details?.required }}</div>
        </div>
        <div class="borderss"></div>
        <div class="titles" style="margin-top: 20px">公司信息</div>
        <div class="companyInfo" @click="goDetails" style="cursor: pointer;">
          <img style="border-radius: 6.67px" :src="details?.company?.logo?.url">
          <div class="right">
            <div class="name">{{ details?.company?.name }}</div>
            <div style="display: flex">
              <div class="desc" v-for="item in details?.company?.tags" :key="item">{{ item }}<span>·</span>
              </div>
            </div>
          </div>
        </div>
        <div class="borderss"></div>
        <div class="titles" style="margin-top: 20px">工作地址</div>
        <div class="adresss">
          <div class="item">
            <img src="https://static.dasdt.cn/images/adress2.png">
            <span class="adressShengLue">{{ details?.company?.address }}</span>
          </div>
          <div class="item">
            <img style="margin-right: 2px" src="https://static.dasdt.cn/images/phone2.png">
            <span>联系电话：{{ details?.company?.phone }}</span>
          </div>
        </div>
        <div style="height: 300px;width: 100%;margin-top: 16px" @click="open">
          <MapContainer :info="details?.company"/>
        </div>
        <div class="ViewMores" @click="goDetails">查看更多信息</div>
      </div>
    </div>
    <el-dialog
        :visible.sync="dialogVisible"
        center
        width="1200px"
        @open="initMap"
        :before-close="handleClose">
      <div class="mapDialog" style="padding-bottom: 20px" slot="footer">
        <div style="color: #141414;font-size: 18px;font-weight: 500;margin-bottom: 16px;display: flex">公司地址</div>
        <div class="adresss">
          <div class="item">
            <img src="https://static.dasdt.cn/images/adress2.png">
            <span>{{ details?.company?.address }}</span>
          </div>
          <div class="item">
            <img style="margin-right: 2px" src="https://static.dasdt.cn/images/phone2.png">
            <span>联系电话：{{ details?.company?.phone }}</span>
          </div>
        </div>
        <div style="height: 480px;width: 100%;margin-top: 16px">
          <MapContainer id="container2" :info="{...details.company,abc: 'abc'}"/>
        </div>
      </div>

    </el-dialog>
  </div>
</template>
<script>
import Appointment from "@/views/home/positionItem.vue";
import MapContainer from "@/components/MapContainer/index.vue";
import {cancelByJid, jobMember, JobsDetail, memberCollection} from "@/api/position";

export default {
  components: {MapContainer, Appointment},
  props: {
    appointmentList: {
      type: Array,
      default: () => []
    },
    totalCount: {
      type: Number,
      default: 0
    }
  },
  watch: {
    appointmentList: {
      handler(val) {
        console.log(val[0].id)
        if (val.length>0) {
          this.JobsDetail(val[0].id)
        }
      },
      deep: true,
      immediate: true
    },
    // appointmentList(val) {
    //   console.log(val[0].id)
    //   if (val.length>0) {
    //     this.JobsDetail(val[0].id)
    //   }
    // }
  },
  data() {
    return {
      isShow: true,
      dialogVisible: false,
      show: false,
      map: null,
      details: {}

    }
  },
  methods: {
    goDetails() {
      this.$router.push(`/positionDetails?id=${this.details.id}`)
    },
    handleCurrentChange(el) {
      this.$emit('handleCurrentChange', el)
    },
    async deliver() {
      if(Object.keys(this.$store.getters.userInfo).length>0){
        const info = this.$store.getters.userInfo
        if(info.customJobTag?.length === 0 || !info.birthDay || !info.email || info.working?.length === 0 || info.project?.length === 0 || info.cateIdsComment?.length === 0) {
          this.$message({
            message: '请先完善个人简历',
            type: 'warning'
          });
          this.$router.push('/PersonalCenter?deletion=1')
          return
        }
      }
      const res = await jobMember({
        advantages: '',
        jid: this.details.id,
        bid: this.details.bid,
      })
      if (res.code === 'A00000') {
        this.details.isDeliver = !this.details.isDeliver
        this.$message({
          message: '投递成功',
          type: 'success'
        })
      }
    },
    async eventCollection() {
      if (this.isShow) {
        const {data} = await memberCollection({type: 'POSITION', jid: this.details.id})
        console.log(data)
        this.$message({
          message: '收藏成功',
          type: 'success'
        });
      } else {
        const {data} = await cancelByJid(this.details.id)
        console.log(data)
        this.$message({
          message: '取消成功',
          type: 'success'
        });
      }
      this.isShow = !this.isShow
    },
    eventCall(id) {
      this.JobsDetail(id)
    },
    async JobsDetail(id) {
      const {data} = await JobsDetail(id)
      this.isShow = !data[0].isCollection
      this.details = data[0]
    },
    open() {
      this.dialogVisible = true

    },
    handleClose() {
      this.dialogVisible = false
    },
    initMap() {

    },

  }
}
</script>
<style scoped lang="scss">
.recruitmentPositions {
  .mapDialog {

  }

  .adresss {
    display: flex;
    align-items: center;

    .item {
      width: 50%;
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }

      span {
        color: #555555;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .detailsWrap {
    display: flex;
    gap: 16px;
    .AppointmentWrap{
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      overflow-x: hidden;
    }
    .AppointmentWrap::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }

    .details {
      margin-top: 16px;
      border-radius: 8px;
      padding: 16px;
      flex: 1;
      background: #fff;

      .ViewMores {
        margin: 32px auto;
        width: 200px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #185BE0;
        color: #185BE0;
        font-size: 14px;
        font-weight: 400;
        border-radius: 4px;
        cursor: pointer;
      }

      .companyInfo {
        display: flex;
        align-items: center;
        gap: 6px;

        img {
          width: 44px;
          height: 44px;
        }

        .right {
          display: flex;
          flex-direction: column;
          gap: 6px;

          .name {
            color: #333333;
            font-size: 16px;
            font-width: 500;
          }

          .desc {
            color: #808080;
            font-size: 14px;
            display: flex;

            span {
              display: none;
            }
          }

          .desc:not(:last-child) {
            span {
              display: block !important;
            }
          }
        }
      }

      .borderss {
        margin-top: 20px;
        border-bottom: 1px solid #E6E6E6;
      }

      .responsibilityWrap {
        margin-top: 20px;

        .responsibility {
          color: #333333;
          font-size: 14px;
          line-height: 26px;
          font-width: 400;
        }
      }

      .titles {
        margin-bottom: 16px;
        margin-top: 32px;
        color: #141414;
        font-size: 18px;
        font-width: 500;

      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btnList {
          display: flex;
          align-items: center;
          gap: 14px;

          .item {
            cursor: pointer;
            width: 88px;
            height: 34px;
            border-radius: 4px;
            background: #3A76FF;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 14px;
            font-width: 400;
          }

          .star {
            cursor: pointer;
            background: #ffffff;
            width: 80.08px;
            height: 34px;
            border-radius: 4px;
            border: 1px solid #3A76FF;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;

            img {
              width: 14px;
              height: 14px;
            }

            span {
              font-size: 14px;
              color: #3A76FF;
              font-weight: 500;
            }
          }
        }

        .info {
          .name {
            color: #222222;
            font-size: 20px;
            font-width: 500;
          }

          .infoList {
            margin-top: 10px;
            display: flex;
            align-items: center;
            gap: 24px;

            .item {
              display: flex;
              align-items: center;
              gap: 6px;

              img {
                width: 16px;
                height: 16px;
              }

              span {
                color: rgba($color: #000000, $alpha: 0.65);
                font-size: 14px;
                font-width: 400;
              }
            }
          }
        }
      }
    }
  }
}
</style>
